import {
  getMint,
  getAssociatedTokenAddress,
  getAccount,
  createTransferCheckedInstruction,
  TOKEN_PROGRAM_ID,
  getOrCreateAssociatedTokenAccount,
  AccountLayout,
} from '@solana/spl-token'

import {
  clusterApiUrl,
  Connection,
  PublicKey,
  Transaction,
} from '@solana/web3'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

export default defineNuxtPlugin(({ $toast }) => {
  const bufferToBase64 = async(buffer) => {
    // use a FileReader to generate a base64 data URI:
    const base64url = await new Promise(r => {
      const reader = new FileReader()
      reader.onload = () => r(reader.result)
      reader.readAsDataURL(new Blob([buffer]))
    })
    // remove the `data:...;base64,` part from the start
    return base64url.slice(base64url.indexOf(',') + 1)
  }

  const authUser = useAuthUser()
  const openRegistrationPopup = useOpenRegistrationPopup()
  const openDepositPopup = useOpenDepositPopup()
  const jwt = useJWT()

  const { public: config } = useRuntimeConfig()

  const wallet = reactive({
    walletInstalled: false,
    provider: null,
    publicKey: '',
    rawPublicKey: null,
    isConnected: false,
    USDCBalance: 0,
    depositTxt: null,
    withdrawTxt: null,

    async startup() {
      wallet.walletInstalled = window.phantom?.solana?.isPhantom

      console.log('walletInstalled', wallet.walletInstalled)

      if (wallet.walletInstalled) {
        wallet.provider = window.phantom?.solana

        console.log('provider', wallet.provider)

        await wallet.setupEvents()

        try {
          const { publicKey } = await toRaw(wallet.provider).connect({ onlyIfTrusted: true })

          console.log('publicKey', publicKey)

          wallet.isConnected = true
          wallet.publicKey = publicKey.toString()
          wallet.rawPublicKey = publicKey
          console.log('wallet', wallet)

          // check token expiration
          const token = localStorage.getItem('jwt') || ''
          console.log('token', token)

          const isTokenExpired = Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000

          console.log('isTokenExpired', isTokenExpired)

          if (isTokenExpired) {
            jwt.value = null
            // Token expired
            await wallet.connectWallet()
          } else {
            jwt.value = token
            await wallet.getUser()
            await wallet.checkDeposit()
          }
        } catch(err) {
          // Ignore the error
          console.error(err)
        }
      }

      console.log(wallet)
    },

    async setupEvents() {
      toRaw(wallet.provider).on('accountChanged', (publicKey) => {
        console.log('accountChanged', publicKey, publicKey.toString())

        if (publicKey) {
          wallet.publicKey = publicKey.toString()
          wallet.rawPublicKey = publicKey
        } else {
          wallet.publicKey = ''
          wallet.rawPublicKey = null
          wallet.isConnected = false
        }
      })

      toRaw(wallet.provider).on('disconnect', () => {
        wallet.isConnected = false
        wallet.publicKey = ''
        wallet.rawPublicKey = null
        localStorage.removeItem('jwt')
      })

      toRaw(wallet.provider).on('connect', async() => {
        console.log('connect event')

        await wallet.getUser()
        await wallet.getUSDCBalance()

        setInterval(async() => {
          await wallet.getUser()
          await wallet.getUSDCBalance()
        }, 60_000)
      })
    },

    async connectWallet() {
      if (authUser.value.username) {
        $toast.success('Already connected')

        return
      }

      if (wallet.walletInstalled) {
        try {
          const resp = await toRaw(wallet.provider).connect()
          wallet.publicKey = resp.publicKey.toString()
          wallet.rawPublicKey = resp.publicKey
          wallet.isConnected = true

          console.log('publicKey', wallet.publicKey)
        } catch (err) {
          if (err.code === 4001) {
            $toast.error(err.message)
          }

          wallet.isConnected = false
        }

        if (wallet.isConnected && wallet.publicKey) {
          // start signin process
          await wallet.signin()
        }
      } else {
        const url = encodeURIComponent(window.location.href)
        window.open(`https://phantom.app/ul/browse/${ url }?ref=${ url }`, '_blank')
      }
    },

    async signin() {
      try {
        // Request user nonce
        const { nonce } = await fetch(`${ config.apiUrl }/auth/nonce?address=${ wallet.publicKey }`, {
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(r => r.json())

        console.log('nonce', nonce)

        // Request message signature
        const message = `I am signing my one-time nonce: ${ nonce }`

        const encodedMessage = new TextEncoder().encode(message)
        const signedMessage = await toRaw(wallet.provider).request({
          method: "signMessage",
          params: {
            message: encodedMessage,
          },
        })

        console.log('signed message', signedMessage)

        const tokenRes = await fetch(`${ config.apiUrl }/auth/login`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            signature: await bufferToBase64(signedMessage.signature),
            publicKey: signedMessage.publicKey.toString()
          })
        })

        const r = await tokenRes.json()

        if (!tokenRes.ok) {
          console.error(r)

          $toast.error(r.message)

          return
        }

        const { token } = r

        console.log('token', token)

        // Save token to local storage
        localStorage.setItem('jwt', token)

        jwt.value = token

        await wallet.getUser()

        // Open complete profile popup if needed
        if (!authUser.value.email || !authUser.value.username) {
          //
          openRegistrationPopup.value = true
        }
      } catch(err) {
        console.error(err)

        $toast.error(err.message)
      }
    },

    async getUser() {
      const res = await fetch(`${ config.apiUrl }/user`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ localStorage.getItem('jwt') }`
        }
      })

      const user = await res.json()

      authUser.value = user

      console.log('auth user', authUser)
    },

    async getUSDCBalance() {
      const USDC_MINT = config.USDCMint

      const clusterUrl = config.chainType === 'devnet' ? clusterApiUrl(config.chainType) : config.chainType
      const connection = new Connection(clusterUrl, "confirmed")

      const accounts = await connection.getTokenAccountsByOwner(toRaw(wallet.rawPublicKey), {
        mint: new PublicKey(USDC_MINT),
      })

      if (accounts?.value.length) {
        const account = accounts.value[0]
        console.log('accout', account)

        const decoded = AccountLayout.decode(account.account.data)
        console.log('decoded', decoded)

        wallet.USDCBalance = Number(decoded.amount) / 1_000_000
      }
    },

    async deposit(value) {
      try {
        wallet.depositTxt = 'Deposit in progress'

        let amount = Number(value)
        console.log('amount', amount)

        if (amount <= 0) {
          $toast.error('Deposit value must be greater than 0')
          wallet.depositTxt = null

          return false
        }

        if (wallet.USDCBalance < amount) {
          $toast.error('Your USDC balance is not enough')
          wallet.depositTxt = null

          return false
        }

        const splToken = new PublicKey(config.USDCMint)
        const sender = new PublicKey(wallet.publicKey)
        const recipient = new PublicKey(config.recipientPubKey)

        const clusterUrl = config.chainType === 'devnet' ? clusterApiUrl(config.chainType) : config.chainType
        const connection = new Connection(clusterUrl, "confirmed")
        console.log('connection', connection)


        // Check user balance
        const balance = await connection.getBalance(sender)
        const lamportBalance = (balance / LAMPORTS_PER_SOL)

        if (lamportBalance <= 0) {
          $toast.error('Your SOL balance is not enough')
          wallet.depositTxt = null

          return false
        }


        const mint = await getMint(connection, splToken)
        console.log('mint', mint)

        amount = amount * (10 ** mint.decimals)

        const senderATA = await getAssociatedTokenAddress(splToken, sender)
        console.log('senderATA', senderATA)

        const senderAccount = await getAccount(connection, senderATA)
        console.log('senderAccount', senderAccount)

        if (!senderAccount.isInitialized) {
          $toast.error('sender not initialized')
          wallet.depositTxt = null

          return false
        }

        if (senderAccount.isFrozen) {
          $toast.error('sender frozen')
          wallet.depositTxt = null

          return false
        }

        // Get the recipient's ATA and check that the account exists and can receive tokens
        const recipientATA = await getAssociatedTokenAddress(splToken, recipient)
        const recipientAccount = await getAccount(connection, recipientATA)
        console.log('recipientAccount', recipientAccount)

        if (!recipientAccount.isInitialized) {
          $toast.error('recipient not initialized')
          wallet.depositTxt = null

          return false
        }

        if (recipientAccount.isFrozen) {
          $toast.error('recipient frozen')
          wallet.depositTxt = null

          return false
        }

        // Check that the sender has enough tokens
        const tokens = BigInt(String(amount))
        if (tokens > senderAccount.amount) {
          $toast.error('Insufficient funds')
          wallet.depositTxt = null

          return false
        }

        // Create an instruction to transfer SPL tokens, asserting the mint and decimals match
        const instruction = createTransferCheckedInstruction(senderATA, splToken, recipientATA, sender, tokens, mint.decimals)
        console.log('instruction', instruction)

        const transaction = new Transaction()
        transaction.feePayer = sender
        transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash

        // Add the transfer instruction to the transaction
        transaction.add(instruction)

        const { signature } = await toRaw(wallet.provider).signAndSendTransaction(transaction, {skipPreflight: false})

        console.log('signature', signature)

        wallet.depositTxt = 'Waiting confirmations'

        localStorage.setItem('latestSignature', signature)

        await wallet.checkDeposit()
      } catch (error) {
        console.error(error)

        $toast.error(error.message)

        wallet.depositTxt = null
      }
    },

    async checkDeposit() {
      const signature = localStorage.getItem('latestSignature')

      const checkSignature = async() => {
        const { status } = await fetch(`${ config.apiUrl }/deposit`, {
          timeout: 2_000,
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ localStorage.getItem('jwt') }`
          },
          body: JSON.stringify({
            signature
          })
        }).then(r => r.json())

        return status
      }

      if (signature) {
        const status = await checkSignature()

        if (status === 'ko') {
          const intVal = setInterval(async() => {
            const status = await checkSignature()

            if (status === 'ok') {
              wallet.depositTxt = null

              clearInterval(intVal)

              localStorage.removeItem('latestSignature')

              openDepositPopup.value = false

              await wallet.getUser()
              await wallet.getUSDCBalance()
            }
          }, 2_000)
        } else if (status === 'ok') {
          wallet.depositTxt = null

          localStorage.removeItem('latestSignature')
          openDepositPopup.value = false

          await wallet.getUser()
          await wallet.getUSDCBalance()
        } else {
          $toast.error('Undefined error')
          wallet.depositTxt = null
        }
      }
    },

    async withdraw(value) {
      wallet.withdrawTxt = 'Withdraw in progress'

      let amount = Number(value)

      if (amount <= 0) {
        $toast.error('Withdraw value must be greater than 0')

        return false
      }

      const { status } = await fetch(`${ config.apiUrl }/withdraw`, {
        timeout: 180_000,
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ localStorage.getItem('jwt') }`
        },
        body: JSON.stringify({
          amount
        })
      }).then(r => r.json())

      console.log('withdraw', status)

      wallet.withdrawTxt = 'Withdraw completed'

      await wallet.getUser()
      await wallet.getUSDCBalance()

      wallet.withdrawTxt = null
      openDepositPopup.value = false
    },
  })

  return {
    provide: {
      wallet
    }
  }
})