import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
export default [
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  }
]